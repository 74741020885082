@import "themes/colors";

.header :global {
  .menu {
    background-color: $menu-background;

    .navbar-toggler {
      color: white;
      border-color: white;

      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
      }
    }

    .nav-item {
      padding: 0 20px;

      &:not(:last-child) {
        border-right: 2px solid $menu-item-divider;
      }

      &:hover {
        .title,
        .subtitle {
          color: $primary;
        }
      }

      .title {
        color: $menu-item-title;
        font-size: 1.2rem;
        margin-bottom: 4px;
      }
      .subtitle {
        color: $menu-item-subtitle;
        font-size: 0.9rem;
        margin: 0;
      }
    }
  }
}
