@import "themes/colors";

.main :global {
  background: $dashboard-background;
  color: #4b5367;

  h1 {
    margin: 40px 0;
  }

  .fill {
    min-height: 100vh;
  }
}
