.home :global {
  .welcome {
    margin-top: 8rem;
  }

  .dashboard {
    .bold-text {
      font-size: 2rem;
      font-weight: bold;
    }

    .badge {
      margin: 2px;
      text-transform: lowercase;
      padding: 6px 8px;
      letter-spacing: 0.05rem;
    }
  }
}
